<template>
  <div class="row">
    <div class="col-sm-12 col-lg-6">
        Total de registros: {{ data.total }} | Total de páginas: {{ data.last_page }} | Por página: <select v-model="options.limit" name="paginacion.per_page" id="paginacion.per_page" @change="cambiar_por_pagina">
          <option value="20" :selected="data.per_page == 20">20</option>
          <option value="50" :selected="data.per_page == 50">50</option>
          <option value="100" :selected="data.per_page == 100">100</option>
          <option value="200" :selected="data.per_page == 200">200</option>
          <option value="500" :selected="data.per_page == 500">500</option>
        </select>
      </div>
      <div class="col-sm-12 col-lg-6 text-right links_pagination">
        <nav aria-label="Navegacion de paginación">
          <ul class="pagination justify-content-end">
            <li class="page-item"><a class="page-link" href="#" @click.prevent="paginado_saltar(1)">&lt;&lt;</a></li>
            <li class="page-item"><a class="page-link" href="#" @click.prevent="paginado_atrazar">&lt;</a></li>
            <li v-for="pagina in generar_num_paginas" :class="'page-item'+(pagina == data.current_page ? ' active' : '')"><a class="page-link" href="#" @click.prevent="paginado_saltar(pagina)">{{ pagina }}</a></li>
            <li class="page-item"><a class="page-link" href="#" @click.prevent="paginado_avanzar">&gt;</a></li>
            <li class="page-item"><a class="page-link" href="#" @click.prevent="paginado_saltar(data.last_page)">&gt;&gt;</a></li>
          </ul>
        </nav>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
      default: function() {
        return {
          data: [],
          total: 0,
          per_page: 20,
          current_page: 1,
          last_page: 1
        }
      }
    }
  },
  data() {
    return {
      options: {
        page: 1,
        order_col: '_id',
        order_dir: 'desc',
        limit: 20
      }
    }
  },
  mounted() {
    this.options.page = this.data.current_page;
    this.options.limit = this.data.per_page;
  },
  methods: {
    paginado_saltar(pagina) {
      this.options.page = pagina;

      this.$emit('paginado', this.options);
    },
    paginado_atrazar() {
      if (this.options.page == 1)
        return;

      this.options.page--;

      this.$emit('paginado', this.options);
    },
    paginado_avanzar() {
      if (this.options.page >= this.data.last_page)
        return;

      this.options.page++;

      this.$emit('paginado', this.options);
    },
    cambiar_por_pagina() {
      this.$emit('paginado', this.options);
    }
  },
  computed: {
    generar_num_paginas() {
      let inicio = this.data.current_page == 1 ? 1 : (this.data.current_page > 3 ? (this.data.current_page - 3) : 1);
      let final = this.data.current_page == this.data.last_page ? this.data.last_page : (this.data.current_page < (this.data.last_page - 3) ? (this.data.current_page + 3) : this.data.last_page);
      let paginas = [];

      for(let i=inicio; i<=final; i++) {
        paginas.push(i);
      }

      return paginas;
    },
  },
  watch: {
    data: {
      handler() {
        this.options.page = this.data.current_page;
        this.options.limit = this.data.per_page;
      },
      deep: true
    }
  }
}
</script>